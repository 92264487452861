// Code generated by Slice Machine. DO NOT EDIT.

import { defineSliceZoneComponents } from "@prismicio/vue";
import { defineAsyncComponent } from "vue";

export const components = defineSliceZoneComponents({
  blog_content: defineAsyncComponent(() => import("./BlogContent/index.vue")),
  blog_empty: defineAsyncComponent(() => import("./BlogEmpty/index.vue")),
  blog_highlight_title: defineAsyncComponent(
    () => import("./BlogHighlightTitle/index.vue"),
  ),
  blog_image_text: defineAsyncComponent(
    () => import("./BlogImageText/index.vue"),
  ),
  blog_second_title: defineAsyncComponent(
    () => import("./BlogSecondTitle/index.vue"),
  ),
  blog_two_image: defineAsyncComponent(
    () => import("./BlogTwoImage/index.vue"),
  ),
  hero: defineAsyncComponent(() => import("./Hero/index.vue")),
  logo_highlight: defineAsyncComponent(
    () => import("./LogoHighlight/index.vue"),
  ),
});
